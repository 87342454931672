import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <>
    <div className="first-load">
      <img className="egmlogo" src="logo.png"></img>
    </div>
    <br></br>
    <hr id="divider"></hr>
    </>
  );
}

export default App;
